export class ZuploConstants {
    /* RBAC constants */
    static readonly API_PAYMENTS = "API-Payments";
    static readonly API_COMPLIANCE = "API-Compliance";
    static readonly API_ESCROW = "API-Escrow";
    static readonly API_LOANS = "API-Loans";
    static readonly API_BOARDING = "API-Boarding";
    static readonly API_ADMIN = "API-Admin";

    /* Payments constants */
    static readonly PAYMENTS_ID = "1"
    static readonly PAYMENTS_DESCRIPTION = "The Payments API";
    static readonly PAYMENTS_TITLE = "Payments";
    static readonly PAYMENTS_KEYWORD = "payments";

    /* Compliance constants */
    static readonly COMPLIANCE_ID = "2";
    static readonly COMPLIANCE_DESCRIPTION = "The Compliance API";
    static readonly COMPLIANCE_TITLE = "Compliance";
    static readonly COMPLIANCE_KEYWORD = "compliance";

    /* Escrow constants */
    static readonly ESCROW_ID = "3";
    static readonly ESCROW_DESCRIPTION = "The Escrow API";
    static readonly ESCROW_TITLE = "Escrow";
    static readonly ESCROW_KEYWORD = "escrow";

    /* Loans constants */
    static readonly LOANS_ID = "4";
    static readonly LOANS_DESCRIPTION = "The Loans API";
    static readonly LOANS_TITLE = "Loans";
    static readonly LOANS_KEYWORD = "loans";

    /* Boarding constants */
    static readonly BOARDING_ID = "5";
    static readonly BOARDING_DESCRIPTION = "The Boarding API";
    static readonly BOARDING_TITLE = "Boarding";
    static readonly BOARDING_KEYWORD = "boarding";

    /* Admin constants */
    static readonly ADMIN_ID = "6";
    static readonly ADMIN_DESCRIPTION = "The Admin API";
    static readonly ADMIN_TITLE = "Admin";
    static readonly ADMIN_KEYWORD = "admin";

    /* Common constants */
    static readonly SERVER = "https://gateway-dv-b0f8680.zuplo.app";
    static readonly VERSION = "v1";
}

export const apiParams = {
    Z_API_KEY: "z-api-key",
    TENANT_ID: "X-TenantId",
    PLATFORM_ID: "X-PlatformId",
    BRAND_ID: "X-BrandId",
    USER_ID: "X-UserId"
}