import { Auth0Provider } from "@auth0/auth0-react"
import { AppRouter } from "@backstage/core-app-api"
import { AlertDisplay, OAuthRequestDialog } from "@backstage/core-components"
import { Root } from './Root';
import React from "react"
import { PropsWithChildren } from "react"
import {useApi, configApiRef} from '@backstage/core-plugin-api'
import { RecoilRoot } from "recoil";

export const Auth0CustomeProvider = ({ children }: PropsWithChildren<{}>) =>{

    const config = useApi(configApiRef);
    return (<Auth0Provider
        domain={config.get('auth0.domain')}
        clientId={config.get('auth0.clientId')}
        authorizationParams={{
          audience: config.get('auth0.audience'),
          redirect_uri: config.get('app.baseUrl')
        }}
        useRefreshTokensFallback
        cacheLocation='localstorage'
      >
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
        <RecoilRoot>
          <Root>{children}</Root>
          </RecoilRoot>
        </AppRouter>
      </Auth0Provider>)

}